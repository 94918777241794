@import 'null.scss';


.services {
	padding: 40px 0 80px;
	@media (max-width: 840px) {
		padding: 20px 0 80px;
	}

	img {
		border-radius: 20px;
		margin-bottom: 40px;
		object-fit: cover;
		height: 427px;
		@media (max-width: 840px) {
			height: 300px;
		}
	}

	&__header {
		font-weight: 500;
		font-size: 53px;
		line-height: 120%;
		margin-bottom: 20px;
		text-transform: lowercase;
		&::first-letter {
			text-transform: uppercase;
		}
		@media (max-width: 840px) {
			display: none;
		}
	}

	&__container {
		display: grid;
		grid-template-columns: 331px auto;
		gap: 60px;
		@media (max-width: 840px) {
			display: none;
		}
	}

	&__menuItem {
		padding: 20px 0px;
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		color: #C7C7C7;
		cursor: pointer;
		&:not(:last-child) {
			border-bottom: 1px solid #EAEDEE;
		}
		&.active {
			color: #99C455;
		}
	}

	&__displayItem, &__displayItemMobile {
		box-sizing: border-box;
		padding-top: 13px;

		h4 {
			font-weight: 500;
			font-size: 31px;
			line-height: 120%;
			color: #222222;
			margin-bottom: 20px;
			text-transform: lowercase;
			&::first-letter {
				text-transform: uppercase;
			}
		}

		ul {
			margin-left: 20px;
		}

		li {
			color: #222222;
			size: 18px;
			margin-bottom: 20px;
			list-style-type: disc;
			&::marker {
				color: var(--green);
			}
		}
	}

	.hidden {
		display: none;
	}

	&__menuMobile {
		display: none;
		@media (max-width: 840px) {
			display: block;
			position: relative !important;
		}

		.services__mobileHeader {
			position: absolute !important;
			line-height: 47px !important;
			font-size: 24px !important;
			text-transform: lowercase;
			font-weight: 500;
			top: 0;
			left: 0;
			color: var(--green);
			margin-bottom: 0;

			&::first-letter {
				text-transform: uppercase;
			}
		}
		h4 {
			padding-top: 0;
			font-size: 20px;
		}
		.services__displayItemMobile {
			padding-top: 0;
			
		}
		li {
			font-size: 16px;
			&::marker {
				color: #222222;
			}
		}
		.swiper {
			padding-top: 50px;
			height: auto;
		}
		.swiper-button-next {
			top: 0 !important;
			margin-top: 0;
			&::after {
				content: none;
			}
		}

		.swiper-button-prev {
			top: 0;
			left: unset;
			right: 50px;
			margin-top: 0;
			&::after {
				content: none;
			}
		}

		.swiper-button-disabled {
			svg {
				filter: invert(75%) sepia(79%) saturate(12%) hue-rotate(154deg) brightness(120%) contrast(56%);
			}
		}
	}
}
   