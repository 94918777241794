@import 'null.scss';

.my-gallery.about {
    padding: 40px 0 80px;
    @media (max-width: 840px) {
        padding: 20px 0 40px;
    }
}

.my-gallery {
	&__header {
		font-weight: 500;
		font-size: 53px;
		line-height: 120%;
		color: var(--green);
		margin-bottom: 20px;
		@media (max-width: 960px) {
			font-size: 24px;
		}
	}

	&__subheader {
		font-weight: 500;
		font-size: 24px;
		line-height: 120%;
		color: #000000;
		margin-bottom: 40px;
		@media (max-width: 960px) {
			font-size: 18px;
		}
	}

	&__swiperWrapper {
		display: grid;
		gap: 20px;
		/* 
		315 + 825  | 1/2
		740 + 400  | 1/7
		397 + 743  | 1/3
		658 + 482  | 1/6
		488 + 652  | 1/4
		631        | 1/5
		----------------
		1160       | 8
		*/
		grid-template-columns: calc(100% * 315 / 1290) calc(100% * (397 - 325) / 1290) calc(100% * (488 - 397) / 1290) calc(100% * (631 - 488) / 1290) calc(100% * (658 - 631) / 1290) calc(100% * (740 - 658) / 1290) calc(100% * (1160 - 740) / 1290);
		@media (max-width: 960px) {
			display: flex;
			flex-direction: column;
			gap: 0;
			& > div:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}

	&__card-with-swiper {
		padding: 20px;
		background: #F7F7F7;
		border-radius: 20px;
		&:nth-child(1) {
			grid-column: 1 / 2;
		}
		&:nth-child(2) {
			grid-column: 2 / 8;

		}
		&:nth-child(3) {
			grid-column: 1 / 7;
		}
		&:nth-child(4) {
			grid-column: 7 / 8;
		}
		&:nth-child(5) {
			grid-column: 1 / 3;
		}
		&:nth-child(6) {
			grid-column: 3 / 8;
		}
		&:nth-child(7) {
			grid-column: 1 / 6;
		}
		&:nth-child(8) {
			grid-column: 6 / 8;
		}
		&:nth-child(9) {
			grid-column: 1 / 4;
		}
		&:nth-child(10) {
			grid-column: 4 / 8;
		}
		&:nth-child(11) {
			grid-column: 1 / 5;
		}
	}

	.swiper-button-prev {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: rgba(153, 196, 85, 0.5);
		-webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
		transform: matrix(-1, 0, 0, 1, 0, 0);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center;
		&::after {
			content: none;
		}
	}

	.swiper-button-next {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: rgba(153, 196, 85, 0.5);
		-webkit-transform: matrix(-1, 0, 0, 1, 0, 0), rotateX(180deg);
		transform: matrix(-1, 0, 0, 1, 0, 0), rotateX(180deg);
		background-repeat: no-repeat;
		background-size: auto;
		background-position: center;
		&::after {
			content: none;
		}
	}

	&__bottom-padding-for-swiper {
		width: 100%;
		height: 40px;
	}

	&__swiper-text {
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		margin-bottom: 18px;
		@media (max-width: 960px) {
			font-size: 16px;
		}
	}

	&__swiper-button {
		padding: 10px 20px;
		border: 2px solid #5F7935;
		border-radius: 50px;
		background: transparent;
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		color: #5F7935;
		text-transform: uppercase;
		@media (max-width: 960px) {
			font-size: 16px;
		}
	}

	&__bottom-wrapper {
		height: 107px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		@media (max-width: 960px) {
			align-items: unset;
		}
	}

	&__swiper-img {
		height: 427px !important;
		@media (max-width: 960px) {
			height: 350px !important;
		}
	}

	&__slide-container {
		position: relative;
		max-width: 100%;
		max-height: 100%;
		@media (max-width: 960px) {
			max-width: max-content;
			max-height: max-content;
			margin: 0 auto;
		}
	}

	&__img-logo {
		position: absolute !important;
		z-index: 10000;
		bottom: 0;
		left: 0;
		height: 39px !important;
		width: 217px !important;
		object-fit: contain !important;
		// @media (max-width: 960px) {
		// 	display: none;
		// }

	}
}

.swiper {
	height: 467px;
	@media (max-width: 960px) {
		height: 400px;
	}
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 15px !important;
}

.swiper-pagination-bullet {
	height: 10px !important;
	width: 10px !important;
}
.swiper-pagination-bullet-active {
	background: var(--green) !important;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap,2.5px) !important;
}

.my-gallery__swiper-img {
	object-fit: cover;
	height: 100%;
	width: 100%;
	@media (max-width: 960px) {
		max-width: 100%;
	}
}


.swiper-gallery-container:not(:last-child) {
	margin-bottom: 80px;
}

.popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
	background: #FFFFFF;
	border-radius: 20px;
	box-sizing: border-box;
	padding: 40px;
	max-height: calc(100% - 40px);
	max-width: calc(100% - 40px);
	box-shadow: 5px 5px 20px 2px rgba(0,0,0,0.65);
	-webkit-box-shadow: 5px 5px 20px 2px rgba(0,0,0,0.65);
	-moz-box-shadow: 5px 5px 20px 2px rgba(0,0,0,0.65);
	@media (max-width: 960px) {
		width: 100%;
	}

	&__header {
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		color: #222222;
		margin-bottom: 22px;
		text-transform: capitalize;
	}

	svg {
		float: right;
		cursor: pointer;
		height: 12px;
		width: 12px;
	}

	&__iframe-container {
		@media (max-width: 960px) {
			max-width: 100%;
		}
	}
	iframe {
		@media (max-width: 960px) {
			width: 100%;
		}
	}

	&_hidden {
		display: none;
	}
}

.popup__screen {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	pointer-events: none;
	transition: all 300ms ease-in-out;
	z-index: 500;
	&_open {
		background-color: #222222;
		opacity: 0.7;
		pointer-events: unset;
		z-index: 999;
		overflow: hidden;
	}
}