@import 'null.scss';

/* Wrap */
.header {
	background-color: var(--white);
	z-index: 5;
	border-bottom: 1px solid #EAEDEE;
	overflow-x: hidden;
}

.header__body {
	display: flex;
	align-items: center;
	position: relative;
	padding: 20px 0;
}

/* Logo */
.header__logo {
	flex: 1 0 auto;
	@media (max-width: 960px) {
		img {
			width: 205px !important;
		}
	}
}

.header__logo > a {
	display: inline-block;
}

/* Menu */
.header__menu {
	padding-left: 20px;
	font-weight: 600;
	@media (max-width: 1160px) {
		display: none;
	}
}
.header__menu > ul {
	display: flex;
	flex-wrap: wrap;
	
}

.header__menu > ul > li:not(:last-child) {
	// padding-right: 32px;
	padding-right: 12px;
}

.header__menu > ul > li > a,
.header__menu > ul > li:last-child > span {
	text-transform: uppercase;
	color: #000000;
	transition: all 0.1s linear;
	display: inline-block;
	padding: 10px 0;
	font-size: 14px;
}

.sel a {
	color: var(--green) !important;
}

.header__menu > ul > li:last-child > span {
	padding-right: 25px;
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 1L6 6.25L1 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position-y: center;
	background-position-x: right;
	transition: 0.1s all linear;
	cursor: pointer;
}

.header__menu > ul > li:last-child > span:hover,
.header__menu > ul > li:last-child > span:focus {
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 1L6 6.25L1 1' stroke='%2399c455' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

/* Submenu */
/* {
	top: 105px;
	opacity: 1;
	visibility: visible;
} */

.header__submenu {
	position: absolute;
	right: 0;
	top: 130px;
	background: var(--black);
	padding: 24px 16px 32px 24px;
	opacity: 0;
	visibility: hidden;
	transition: 0.1s all linear;
}

.header__submenu.open,
.our-services:hover .header__submenu,
.our-services:focus .header__submenu {
	top: 120px;
	opacity: 1;
	visibility: visible;
}

.header__submenu > ul > li:not(:last-child) {
	margin-bottom: 16px;
}

.header__submenu > ul > li {
	text-align: end;
}

.header__submenu > ul > li > a {
	color: inherit;
	display: block;
	text-transform: uppercase;
}
@media (max-width:1072px) {
	
	.header__menu > ul > li:not(:last-child) {
		padding-right: 20px;
  }

	
}

@media (max-width:840px){
	.header__menu {
		padding-left: 0px;
	}

	.header__body {
		
		padding:  0;
	}
}

.header__hamburger {
	display: none;
	position: relative;

	@media (max-width: 1160px) {
		display: unset;
		align-self: center;
	}

	svg {
		height: 14px;
		width: 17px;
		cursor: pointer;
	}


}

.header__mobileMenu {
	display: none;
	@media (max-width: 1160px) {
		display: unset;
		position: fixed;
		z-index: 1000;
		height: 800px;
		width: 280px;
		background: var(--green);
		right: 0;
		top: 0;
		transition: right 300ms linear;
		box-sizing: border-box;
		padding: 20px;
		opacity: 1 !important;

		&_hidden {
			right: -280px;
		}

		&-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 80px;
		}

		&-menu {
			font-weight: 600;
			font-size: 20px;
			line-height: 120%;
			color: #FFFFFF;
		}

		li {
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 80px;
			}
			a {
				text-transform: uppercase;
				font-weight: 500;
				font-size: 16px;
				line-height: 120%;
				text-align: right;
				color: #FFFFFF;
			}
		}

		&-facebook {
			height: 40px;
			width: 40px;
		}

		.header__mobile-close {
			height: 14px;
			width: 17px;
			cursor: pointer;
		}
		
	}


	
}


.locales {
	flex: 0 0 auto;
	margin-left: 61px;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	@media (max-width: 1160px) {
		margin-left: 20px;
		margin-right: 20px;
	}
	
	&__current-language {
		font-weight: 500;
		font-size: 14px;
		line-height: 120%;
		color: var(--green);
		text-transform: uppercase;
		border: none !important;
		width: 45px;
		height: auto;
		padding: 0;
	}

	&__arrow {
		display: flex;
		align-items: center;
		width: 8px;
		height: 5px;
		transition: all 300ms ease-in-out;
		&_open {
			transform: rotate(180deg);
		}
	}

	&__select {
		position: absolute;
		z-index: 900;
	}
}

.locales__select {
	width: 57px;
	background: var(--white);
	filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
	position: fixed;
	right: calc((100% - 1124px) / 2);
	border-radius: 10px;
	top: 53px;
	opacity: 0;
	pointer-events: none;
	transition: all 300ms ease-in-out;
	@media (max-width: 1160px) {
		right: 57px;
	}
	&_open {
		opacity: 1;
		pointer-events: unset;
	}

	.locales__option {
		width: 100%;
		height: 27px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 14px;
		line-height: 120%;
		color: var(--green);
		cursor: pointer;
	}
}


.header__mobileMenu-screen {
	position: fixed;
	top: 0;
	height: 100vh;
	width: 100vw;
	pointer-events: none;
	transition: all 300ms ease-in-out;
	z-index: 999;
	&.darkened {
		background-color: #222222;
		opacity: 0.7;
		pointer-events: unset;
	}
}

.stop-scrolling {
    height: 100%;
    overflow: hidden;
}