@import 'null.scss';

.good-to-know.about {
    padding: 40px 0 80px;
    @media (max-width: 840px) {
        padding: 20px 0 40px;
    }
}

.good-to-know__top-container {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto 590px;
    column-gap: 40px;
    row-gap: 24px;
    @media (max-width: 1142px) {
        row-gap: 20px;
    }

    @media (max-width: 1062px) {
        grid-template-columns: auto;

    }

    &-header {
        grid-row: 1;
        grid-column: 1;
        text-transform: lowercase;
        font-weight: 500;
        font-size: 53px;
        line-height: 120% !important;
        margin-bottom: unset;
        &::first-letter {
            text-transform: uppercase;
        }

        @media (max-width: 1142px) {
            font-size: 32px;
        }

        @media (max-width: 840px) {
            font-size: 24px !important;
        }
    }

    &-img {
        grid-row: 1 / 3;
        border-radius: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        box-sizing: border-box;

        @media (max-width: 1062px) {
            grid-row: 2;
            width: 100%;
            height: 0;
            padding-top: 60.5%;

        }

        @media (max-width: 840px) {
            padding-top: 78%;
            background-position: center;
        }
    }

    &-text-block {
        align-self: end;
        @media (max-width: 840px) {
            margin-top: 20px;
        }
    }

    .service__subtitle {
        font-weight: 500;
        font-size: 31px;
        line-height: 120%;
        color: #222222;
        margin-bottom: 20px;
        @media (max-width: 1142px) {
            font-size: 22px;
        }
        @media (max-width: 840px) {
            font-size: 20px;
        }
    }

    .about__text {
        margin-bottom: unset;

        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;
            color: #222222;
        }

        .about__text_list > li:not(:last-child) {
            margin-bottom: 20px;
        }

        a {
            color: var(--green);
        }
    }
}

.about__list {
    margin: 80px 0;
    box-sizing: border-box;
    padding: 40px;
    display: grid;
    grid-template-columns: auto 550px;
    column-gap: 40px;
    background: #F7F7F7;
    border-radius: 20px;
    @media (max-width: 840px) {
        grid-template-columns: auto;
        margin: 40px 0;
        padding: 20px;
        row-gap: 20px;
    }

    .service__subtitle {
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        color: #222222;
        margin-bottom: unset;
        @media (max-width: 840px) {
            font-size: 20px;
        }
    }

    .about__list_list {
        margin-left: 20px;
        
        li {
            list-style: disc;
            @media (max-width: 840px) {
            }
            &::marker {
                color: var(--green);
            }
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 120%;
            @media (max-width: 840px) {
                font-size: 16px;
                display: inline;
            }
        }
    }
}

.good-to-know__links {
    .service__subtitle {
        font-weight: 500;
        font-size: 31px;
        line-height: 120%;
        color: #222222;
        margin-bottom: 20px;
        @media (max-width: 840px) {
            font-size: 20px;
        }

    }

    li {
        padding-left: 0;
        background-image: unset;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    a {
        color: var(--green);
        @media (max-width: 840px) {
            font-size: 16px;
        }
    }
}
