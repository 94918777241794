* {
	padding: 0;
	margin: 0;
	border: 0;
}

*,
*:before,
*:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

:focus,
:active {
	outline: none;
}
a:focus,
a:active {
	outline: none;
}

nav,
footer,
header,
aside {
	display: block;
}

html,
.body {
	height: 100%;
	width: 100%;
	font-size: 100%;
	line-height: 1;
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

input,
button,
textarea {
	font-family: inherit;
}

input::-ms-clear {
	display: none;
}
button {
	cursor: pointer;
}
button::-moz-focus-inner {
	padding: 0;
	border: 0;
}
a,
a:visited {
	text-decoration: none;
}
a:hover {
	text-decoration: none;
}
ul li {
	list-style: none;
}
img {
	vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: 400;
}


@font-face {
	font-family: 'Segoe UI Bold';
	src: local('Segoe UI Bold'), url('../fonts/segoe-ui/Segoe\ UI\ Bold.ttf');
}

@font-face {
	font-family: 'Segoe UI';
	src: local('Segoe UI'), url('../fonts/segoe-ui/Segoe\ UI.ttf');
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/poppins/Poppins-Regular.ttf');
	font-weight: 400;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/poppins/Poppins-Medium.ttf');
	font-weight: 500;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/poppins/Poppins-SemiBold.ttf');
	font-weight: 600;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/poppins/Poppins-Bold.ttf');
	font-weight: 700;
}

@font-face {
	font-family: 'Muller';
	src: url('../fonts/muller/MullerRegular.ttf');
	font-weight: 400;
}

@font-face {
	font-family: 'Muller';
	src: url('../fonts/muller/MullerMedium.ttf');
	font-weight: 500;
}

@font-face {
	font-family: 'Muller';
	src: url('../fonts/muller/MullerBold.ttf');
	font-weight: 600;
}

@font-face {
	font-family: 'Muller';
	src: url('../fonts/muller/MullerExtraBold.ttf');
	font-weight: 700;
}

:root {
	--grey: #191919;
	--light-grey: #C7C7C7;
	--black: #000;
	--white: #fff;
	--dark-green: #5F7935;
	--green: #99c455;
	--hover: #9dbe67;
	--opwhite: rgba(255, 255, 255, 0.7);
}

.body {
	background-color: var(--white);
	// font-family: 'Muller', sans-serif;
	font-family: 'Roboto';
	font-size: 16px;
	line-height: 21px;
}

.container {
	max-width: 1160px;
	margin: 0 auto;
	padding: 0 20px;
}

img {
	max-width: 100%;
}

h3 {
	font-size: 40px;
	line-height: 53px;
	text-transform: uppercase;
	color: var(--green);
	margin-bottom: 40px;
}

h4 {
	font-size: 24px;
	line-height: 32px;
	text-transform: uppercase;
	color: inherit;
	margin-bottom: 8px;
}
