@import 'null.scss';

.powered-by{
   width: 222px;
   cursor: pointer;
   display: flex;
   align-items:center;
   justify-content: center;
}
.powered-by>a{
   width: 222px;
   height:47px;
   position: relative;
}
.powered-by__imgBlack{
transition:all 0.2s;
   top: 0;
   left: 0;
   position: absolute;
   width: 100%;
   height:100%;
   z-index: 1;
   opacity: 1;
}
.powered-by__imgColor{
   transition:all 0.2s;
   top: 0;
   left: 0;
   position: absolute;
   width: 100%;
   height: 100%;
   z-index: 0;
   opacity: 0;
}
.powered-by:hover .powered-by__imgColor{
   opacity: 1;
}
.powered-by:hover .powered-by__imgBlack{
   opacity: 0;
}

.footer {
	background-color: #222222;
	padding: 40px 0;
}

.footer__top-flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 51px;
	color: var(--white);
	@media (max-width: 840px) {
		flex-direction: column;
		margin-bottom: 30px;
	}
}

.footer__logo {
	@media (max-width: 840px) {
		width: 214px;
		margin-bottom: 20px;
	}
}

.footer__bottom-flex {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: flex-end;
	@media (max-width: 840px) {
		flex-direction: column;
		align-items: center;
	}
}

/* Menu */

.footer__menu > ul {
	display: flex;
	margin: 0 -10px;
	justify-content: center;
	@media (max-width: 840px) {
		flex-direction: column;
	}
}

.footer__menu > ul > li {
	display: flex;
	padding: 0 10px;
	@media (max-width: 840px) {
		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}

}

.footer__menu > ul > li > a,
.footer__menu > ul > li > span {
	text-transform: uppercase;
	color: inherit;
	transition: 0.1s all linear;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	@media (max-width: 840px) {
		display: inline-block;
		margin: 0 auto;
	}
}

.footer__menu > ul > li > a:hover,
.footer__menu > ul > li > a:focus,
.footer__menu > ul > li > span:focus,
.footer__menu > ul > li > span:hover {
	color: var(--hover);
}

.footer__our-services > span {
	cursor: pointer;
}

/* Copyright */
.footer__policy {
	display: flex;
	flex-direction: column;
	width: 222px;
	@media (max-width: 840px) {
		align-items: center;
		margin-bottom: 30px;
	}
}

.footer__policy > li:not(:last-child) {
	margin-bottom: 10px;
}

.footer__policy > li > a {
	font-size: 14px;
	line-height: 19px;
	color: var(--opwhite);
}

.footer__copyright {
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	color: var(--opwhite);
	font-family: 'Segoe UI';
	@media (max-width: 840px) {
		width: 218px;
		margin-bottom: 30px;
	}
}
