@import 'null.scss';


.about {
	padding: 40px 0 80px;
	@media (max-width: 840px) {
		padding: 20px 0;
	}
}

.overview {
	background: #F7F7F7;
	border-radius: 20px;
	display: flex;
	margin-bottom: 80px;
	height: 347px;
	@media (max-width: 1060px) {
		height: unset;
	}
	@media (max-width: 840px) {
		flex-direction: column;
		margin-bottom: 40px;
	}

	&__text-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1 1 50%;
		box-sizing: border-box;
		padding: 40px;
		@media (max-width: 840px) {
			padding: 20px;
		}
		h3 {
			font-weight: 500;
			font-size: 53px;
			line-height: 120%;
			margin-bottom: 20px;
			text-transform: lowercase !important;
			&::first-letter {
				text-transform: uppercase;
			}
			@media (max-width: 840px) {
				font-size: 24px;
				margin-bottom: 10px;
			}
		}
	}

	&__text {
		margin-right: 32px;
		font-weight: 400;
		font-size: 18px;
		line-height: 25px;
		@media (max-width: 840px) {
			font-size: 16px;
			margin-right: 0;
		}
	}

	&__image-container {
		flex: 1 1 50%;
		max-height: 100%;
		max-width: 100%;
		@media (max-width: 840px) {
			max-height: unset;
		}

		img {
			height: 100%;
			width: 100%;
			border-radius: 20px;
			object-fit: cover;
			@media (max-width: 840px) {
				height: 300px;
			}
		}
	}
}

.portfolio {
	@media (max-width: 840px) {
		flex-direction: column-reverse;
	}
	&__image-container {
		flex: 1 1 50%;
		max-height: 100%;
		max-width: 100%;
		@media (max-width: 840px) {
			max-height: unset;
		}

		img {
			height: 100%;
			width: 100%;
			border-radius: 20px;
			object-fit: cover;
			@media (max-width: 840px) {
				height: 300px;
			}
		}
	}

	&__text-container {
		flex: 1 1 50%;
		box-sizing: border-box;
		padding: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		p {
			font-weight: 400;
			font-size: 18px;
			line-height: 120%;
			margin-left: 20px;
			@media (max-width: 840px) {
				margin-left: 0;
				font-size: 16px;
			}
		}

		ul {
			margin-left: 20px;
			@media (max-width: 840px) {
				margin: 20px 0 20px 20px;
			}
		}

		li {
			font-weight: 500;
			font-size: 18px;
			line-height: 120%;
			margin-left: 20px;
			list-style: disc;
			@media (max-width: 840px) {
				margin-left: 0;
				font-size: 16px;
			}
		}
	}
}

.success-factor {
	background: #99C455;
	border-radius: 20px;
	text-align: center;
	box-sizing: border-box;
	padding: 40px 75.5px;
	font-weight: 500;
	font-size: 31px;
	line-height: 120%;
	color: #FFFFFF;
	margin-bottom: 80px;
	@media (max-width: 840px) {
		padding: 40px 10px;
		font-size: 24px;
		margin-bottom: 40px;
	}
}

.menu {
	&__buttons {
		display: flex;
		justify-content: center;
		background: #F7F7F7;
		border-radius: 100px;
		margin: 0 auto 40px;
		width: max-content;
		@media (max-width: 840px) {
			margin: 0 auto 20px;
			width: 100%;
		}
	}

	&__button {
		box-sizing: border-box;
		padding: 20px 40px;
		background: transparent;
		font-weight: 500;
		font-size: 24px;
		line-height: 120%;
		color: #C7C7C7;
		text-transform: uppercase;
		@media (max-width: 840px) {
			padding: 20px 10px;
			flex-grow: 1;
			font-size: 14px;
			line-height: 21px;
		}
		&.active {
			background: #5D7834;
			border-radius: 100px;
			color: var(--white);
		}
	}

	&__output_mission {
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 20px;
		@media (max-width: 840px) {
			grid-template-columns: 100%;
		}
	}

	.hidden {
		display: none;
	}
}

.mission-card {
	box-sizing: border-box;
	padding: 30px;
	background: #F7F7F7;
	border-radius: 20px;

	&__imgContainer {
		height: 50px;
		width: 50px;
		margin-bottom: 20px;
		position: relative;
	}

	img {
		position: absolute;
		left: 1px;
		height: 100%;
		width: 100%;
		object-fit: contain;
	}

	h4 {
		font-weight: 500;
		font-size: 24px;
		line-height: 120%;
		color: #222222;
		text-transform: lowercase;
		margin-bottom: 10px;
		&::first-letter {
			text-transform: uppercase;
		}
	}

	p {
		font-size: 18px;
		line-height: 120%;
		color: #222222;
	}
}

.menu__output_whyChoose {
	max-width: 900px;
	margin: 0 auto;
	& > div {
		// height: 120px;
		display: flex;
		align-items: center;
		@media (max-width: 840px) {
			height: unset;
		}
	}

	.index {
		flex: 0 0 70px;
		box-sizing: border-box;
		text-align: center;
		font-weight: 500;
		font-size: 53px;
		line-height: 120%;
		color: #99C455;
		margin-right: 25px;
	}

	.reason {
		font-weight: 500;
		font-size: 18px;
		line-height: 120%;
		color: #000000;
		box-sizing: border-box;
		padding: 38px 0;
		@media (max-width: 840px) {
			padding: 22px 0;
			font-size: 16px;
		}
	}
}