@import "null.scss";

.page {
    padding-bottom: 20px;
    p {
        padding-bottom: 10px;
        padding-top: 5px;
        text-align: justify;
    }
    h3 {
        font-weight: 500;
        font-size: 53px;
        line-height: 120%;
        color: var(--green);
        margin-bottom: 20px;
    }

    ul li {
        list-style-type: disc;
        margin-left: 20px;
        padding-bottom: 5px;
    }
    a{
        color:var(--green);
    }
}

.table {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid black;
    @media screen and (max-width:500px) {
        font-size: 12px;
    }
}

.tableRow {
    display: flex;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    div {
        padding: 5px;
        &:first-child {
            font-weight: 700;
            border-right: 1px solid black;
        }
        flex: 1;
    }
}
