@import 'null.scss';

.completedSpread {
    padding: 40px 0 80px;

    h3 {
        font-weight: 500;
        font-size: 53px;
        line-height: 120%;
    }

    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        color: #222222;
    }
}