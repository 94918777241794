@import 'null.scss';
/* Main */

.main {
	box-sizing: border-box;
	padding-top: 40px;

	&__text {
		position: absolute;
		font-weight: 700;
		font-size: 69px;
		line-height: 120%;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: var(--white);
		text-align: center;
		box-sizing: border-box;
		padding: 0 40px;
	}
}

.main .imgContainer {
	width: calc(100vw - 152px);
	height: 640px;
	margin: 0 auto;
	border-radius: 20px;
	position: relative;
}
.main img{
	object-fit: cover;
	border-radius: 20px;
	height: 100%;
	width: 100%;
}
/* News */
.news {
	padding: 40px 0;
	margin-bottom: 81px;
}

.news h3 {
	text-transform: unset;
	font-weight: 500;
	font-size: 53px;
	line-height: 120%;
}

.news__row {
	display: flex;
	flex-wrap: wrap;
	margin: -20px -10px;
}
/* News item */
.news__col {
	padding: 20px 10px 25px;
	width: 50%;
	box-sizing: border-box;
}

.news__item > img {
	margin-bottom: 24px;
	height: 300px;
	object-fit: cover;
	border-radius: 20px;
}

.news__inner-container {
	box-sizing: border-box;
	padding: 0 25px;
}

.news__item p {
	margin-bottom: 30px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-size: 18px;
	line-height: 120%;
	color: #323232;
}

.news__item h4 {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-transform: unset;
	font-weight: 500;
	line-height: 120%;
}

.news__item_link {
	padding: 10px 45px 10px 20px;
	width: max-content;
	white-space: nowrap;
	min-height: 42px;
	border: 2px solid var(--dark-green);
	border-radius: 50px;
	transition: all 0.3s;
}

.news__item_link > a {
	text-transform: uppercase;
	color: var(--dark-green);
	display: inline-block;
	font-weight: 500;
	font-size: 18px;
	line-height: 120%;
	position: relative;
	transition: all 0.3s;

	&::after {
		position: absolute;
		content: '';
		background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6H15M15 6L9.75 1M15 6L9.75 11' stroke='%235F7935' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position-y: center;
		background-position-x: right;
		height: 12px;
		width: 16px;
		top: 50%;
		transform: translate(13px, -50%);
		transition: all 0.3s;
	}
}

.legislation__text:hover,
.legislation__text:focus,
.news__item_link:hover,
.news__item_link:focus {
	border-color: var(--green);
	& a {
		color: var(--green);
		border-color: var(--green);
		&::after {
			background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6H15M15 6L9.75 1M15 6L9.75 11' stroke='%2399C455' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		}
	}
}

/* Legislation */
.legislation__body {
	position: relative;
	height: 465px;

	& img {
		position: absolute;
		border-radius: 20px;
		height: 100%;
		width: 100%;

		object-fit: cover;
	}
}

.legislation__text {
	position: absolute;
	top: 40px;
	left: 40px;
	color: var(--white);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - 86px);
    align-items: flex-start;
	transition: all 0.3s;
}
.legislation__text > h2 {
	font-size: 48px;
	line-height: 64px;
	color: inherit;
	white-space: pre-line;
}

.legislation__text > a {
	text-transform: uppercase;
	color: var(--dark-green);
	display: inline-block;
	transform: translateZ(0);
	width: max-content;
	white-space: nowrap;
	min-height: 42px;
	left: 40px;
	top: 377px;
	border: 2px solid var(--dark-green);
	border-radius: 50px;
	font-weight: 500;
	font-size: 18px;
	line-height: 120%;
	box-sizing: border-box;
	padding: 10px 45px 10px 20px;
	transition: all 0.3s;
	&::after {
		position: absolute;
		content: '';
		background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 6H15M15 6L9.75 1M15 6L9.75 11' stroke='%235F7935' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		background-repeat: no-repeat;
		background-position-y: center;
		background-position-x: right;
		height: 12px;
		width: 16px;
		top: 50%;
		transform: translate(13px, -50%);
		transition: all 0.3s;
	}
}

/* links */
.links {
	padding: 80px 0;

	& h3 {
		text-transform: unset;
		font-weight: 500;
		font-size: 53px;
		line-height: 120%;
	}
}

.links__row {
	display: flex;
	flex-wrap: wrap;
	margin: -11px;
}

.links__col {
	// width: calc(100% / 4);
	// padding: 11px;
	width: 100%;
}
.links__item {
	color: inherit;
}

.links__item > p {
	font-size: 18px;
	transition: 0.1s all linear;

	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-weight: 500;
	line-height: 120%;
	color: #323232;
}

.links__item > img {
	margin-bottom: 20px;
	transition: 0.1s all linear;
	height: 175px;
	border-radius: 20px;
	object-fit: cover;
}

.links__item:hover > img,
.links__item:focus > img {
	opacity: 0.7;
}

.links__item:hover > p,
.links__item:focus > p {
	color: var(--hover);
}

.links .swiper {
	width: 100%;
	height: unset;
}

.margin {
	margin-bottom: 80px;
}


/* Adaptive */
@media (max-width: 1270px) {
	.main .imgContainer {
		width: 100%;
		max-width: 1160px;
		margin: 0 auto;
		padding: 0 20px;
	}

	.main .img {
		width: inherit;
	}

	.main .main__text {
		width: calc(100% - 40px);
	}
}

@media (max-width: 1072px) {
	.main {
		padding-top: 20px;
	}
}
@media (max-width: 840px) {
	/* Main */
	.main {
		&__text {
			font-size: 36px;
			line-height: 120%;
			width: calc(100% - 40px);
		}

		.imgContainer {
			height: 300px;
		}
	}
	/* News */
	.news {
		margin-bottom: 40px;

		h3 {
			font-size: 24px;
			margin-bottom: 20px;
		}

		&__col {
			padding: 20px 10px;
		}

		&__item > img {
			margin-bottom: 20px;
		}

		&__inner-container {
			padding: 0 20px;
			h4 {
				-webkit-line-clamp: 2;
				font-size: 20px;
			}

			.news__item_link {
				width: 161px;
				min-height: 40px;
				a {
					font-size: 16px;
				}
			}
		}
	}
	/* Legislation */
	.legislation__body {
		height: 300px;
	}
	.legislation__body img {
		object-position: 65% top;
	}
	.legislation__text {
		top: 20px;
		left: 20px;
		height: calc(100% - 40px);
		max-width: 246px;
	}
	.legislation__text > h2 {
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 8px;
	}

	.legislation__text > a {
		padding-right: 30px;
		line-height: inherit;
		font-size: 16px;
		width: 161px;
		min-height: 40px;
	}

	/* Links */
	.links {
		padding: 40px 0;

		& h3 {
			font-size: 24px;
			margin-bottom: 20px;
		}

		.swiper-slide {
			width: 273px;
		}
	}

	/* Header */

	.header__logo {
		padding-top: 15px;
		padding-bottom: 15px;
	}

	// .header__menu > ul > li > a,
	// .header__menu > ul > li:last-child > span {
	// 	padding: 10px 0;
	// }

	.header__menu > ul > li:last-child > span {
		padding-right: 25px;
	}

	.header__menu > ul > li {
		padding: 10px !important;
	}

	/* Submenu */
	.header__submenu {
		left: 50%;
		transform: translateX(-50%);
		max-width: 313.467px;
		width: 100%;
	}

	.our-services:hover .header__submenu,
	.our-services:focus .header__submenu,
	.header__submenu.open {
		top: 119px;
		opacity: 1;
		visibility: visible;
	}
}

@media (max-width: 605px) {
	/* News */
	.news__col {
		width: 100%;
	}
}


@media (max-width: 525px) {

	.header__submenu {
		max-width: 100%;
	}

	.header__menu > ul > li > a,
	.header__menu > ul > li:last-child > span {
		font-size: 14px;
		line-height: 19px;
	}

	.header__menu > ul > li {
		padding: 0 !important;
	}

	.header__menu > ul > li > a,
	.header__menu > ul > li:last-child > span {
		padding: 10px;
	}

	.header__menu > ul > li:last-child > span {
		padding-right: 25px;
	}

	.our-services:hover .header__submenu,
	.our-services:focus .header__submenu,
	.header__submenu.open {
		top: 107px;
		opacity: 1;
		visibility: visible;
	}
}

@media (max-width: 466px) {
	.header__submenu {
		top: 156px;
	}
	.our-services:hover .header__submenu,
	.our-services:focus .header__submenu,
	.header__submenu.open {
		top: 146px;
	}
}

@media (max-width: 376px) {
	/* Footer */
	.footer__menu > ul,
	.footer__policy {
		flex-direction: column;
	}
	.footer__copyright {
		text-align: start !important;
	}
}

@media (max-width: 357px) {
	/* Legislation */
	.legislation__text > h2 {
		font-size: inherit;
		line-height: inherit;
	}
}

/* @media (max-width: 352px) {
	.header__submenu {
		top: 150px;
	}
	.our-services:hover .header__submenu,
	.our-services:focus .header__submenu,
	.header__submenu.open {
		top: 146px;
		opacity: 1;
		visibility: visible;
	}
} */
