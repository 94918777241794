.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.completed {
	padding: 240px 0 120px 0;
}

.completed__row {
	display: flex;
	flex-wrap: wrap;
	margin: -20px -16px;
}
/* completed item */
.completed__col {
	padding: 20px 16px;
	width: 50%;
}

.completed__item > img {
	margin-bottom: 24px;
}

.completed__item > p {
	margin-bottom: 16px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.completed__item_link {
	text-align: end;
}

.completed__item_link > a {
	text-transform: uppercase;
	color: var(--green);
	padding-right: 30px;
	background-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.5 6.5H15.5M15.5 6.5L10.25 1.5M15.5 6.5L10.25 11.5' stroke='%2399C455' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position-y: center;
	background-position-x: right;

	display: inline-block;
	transition-property: transform;
	transition-duration: 0.3s;
	transform: translateZ(0);
}

.completed__item_link > a:hover,
.completed__item_link > a:focus {
	transform: translateY(-3px);
}


.completedSpread {
	padding: 240px 0 120px 0;
}

.bg {
	background-image: url('https://esimus-property.com/img/completed/anna__riverside/bg.jpg');
	width: 100%;
	height: 100vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.swiper {
	height: 550px;
}

.swiper-slide > img {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.completedSpread__text_list > li:not(:last-child) {
	margin-bottom: 20px;
}



.completedSpread .completed__row,
.completedSpread__text {
}

/* Adaptive */
@media (max-width: 840px) {
	.completedSpread {
		padding: 200px 0 120px 0;
	}
}
.fetch-loader{
	width:100%;
	height: 100vh;
	/* background-color: var(--grey); */
	background-color: white;
	z-index: 999999999;
}
.pre-loader{
	
	position:fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 999999999;


	
}
.pre-loader .fetch-loader{
	height: 100%;
}
@media (max-width: 605px){
h3 {
    font-size: 34px!important;
    line-height: 47px!important;
}}

.first-page-loader  {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--white);
}