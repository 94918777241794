.to-top {
    position: fixed;
    z-index: 2000;
    bottom: 80px;
    right: 30px;
    width: 48px;
    height: 48px;
    background: #5D7834;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 300ms ease-in-out;
    visibility: visible;
    z-index: 499;

    svg {
        width: 12px;
        height: 16px;
    }
    &.hidden {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }
}